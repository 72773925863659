import React, { createContext, ReactNode, useContext, useState } from 'react'
import { StoreProps } from '../props/StoreProps'
// Contextの型
interface StoreListContextType {
  storeList: StoreProps[]
  setStoreList: (storeList: StoreProps[]) => void
}

// デフォルト値の設定
const StoreListContext = createContext<StoreListContextType>({
  storeList: [],
  setStoreList: () => {},
})

// ContractProviderを作成
export const StoreListProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [storeList, setStoreList] = useState<StoreProps[] | StoreProps[]>([])

  return (
    <StoreListContext.Provider value={{ storeList, setStoreList }}>
      {children}
    </StoreListContext.Provider>
  )
}

// useContractContextフックを作成して簡単にContextにアクセスできるようにする
export const useStoreListContext = () => useContext(StoreListContext)
