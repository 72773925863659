// src/components/Graph.tsx
import { Box } from '@mui/material'
import React from 'react'
import CommonContents from '../../components/CommonContents'
import { SalesTransitionProps } from '../../props/SalesTransitionProps'
import SalesTransitionChart from './SalesTransitionChart'

const SalesTransition: React.FC<SalesTransitionProps> = (props) => {
  // if (loading) {
  //   return <div>Loading...</div> // ローディング表示
  // }

  // if (error) {
  //   return <div>{error}</div> // エラーメッセージ表示
  // }

  // if (!salesTransitionData) {
  //   return <div>No data available</div> // データがない場合の処理
  // }

  return (
    <Box display="flex" flexDirection="column">
      <CommonContents
        title={props.dateType === '0' ? '時間帯実績(売上)' : '日別実績(売上)'}
        content={
          <SalesTransitionChart
            chartType="0"
            dateType={props.dateType}
            data={props.data}
          />
        }
      />
      <Box height={15} /> {/* Spacer */}
      <CommonContents
        title={
          props.dateType === '0'
            ? '時間帯実績(取引数・取引単価)'
            : '日別実績(取引数・取引単価)'
        }
        content={
          <SalesTransitionChart
            chartType="1"
            dateType={props.dateType}
            data={props.data}
          />
        }
      />
      <Box height={15} /> {/* Spacer */}
      <CommonContents
        title={
          props.dateType === '0'
            ? '時間帯実績(買上点数 / 人・商品平均単価)'
            : '日別実績(買上点数 / 人・商品平均単価)'
        }
        content={
          <SalesTransitionChart
            chartType="2"
            dateType={props.dateType}
            data={props.data}
          />
        }
      />
    </Box>
  )
}

export default SalesTransition
