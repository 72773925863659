import { createTheme } from '@mui/material/styles'

// カスタムカラーのプロパティを追加
declare module '@mui/material/styles' {
  interface Palette {
    barChart: Palette['primary']
    lineChart: Palette['primary']
  }
  interface PaletteOptions {
    barChart?: PaletteOptions['primary']
    lineChart?: PaletteOptions['primary']
  }
}

// テーマの定義
const themes = {
  themeColor: createTheme({
    palette: {
      primary: {
        main: '#299D50', // 1. メインの緑色
        light: '#53B22D', // 4. 明るい緑色
        dark: '#379E50', // 8. 暗い緑色
        contrastText: '#F7F7F7', // 2. テキストの色（白に近い）
      },
      secondary: {
        main: '#CDE9DB', // 3. サブカラーとして淡い緑色
      },
      background: {
        default: '#F7F7F7', // 2. 全体の背景色（明るい灰色）
        paper: '#F7F7F7', // 3. カードなどの背景色
      },
      text: {
        primary: '#636363', // テキストのメインカラー
        secondary: '#999999', // 6. サブテキストの色
        disabled: '#B3B3B3', // 7. 無効化されたテキストの色
      },
      barChart: {
        main: '#D9E021',
        light: '#F2F4B2',
      },
      lineChart: {
        main: '#6392CD',
        light: '#A8C3E3',
      },
    },
  }),
  // 他のテーマも同様に定義する場合はここに追加できます
  typography: {
    fontFamily: `'BIZ UDP Gothic', 'Arial', sans-serif`, // フォントを追加
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.4,
    },
  },
}

export default themes
