import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Chart } from 'react-chartjs-2'
import {
  CategorySalesChartProps,
  CategorySalesDataProps,
} from '../../props/CategorySalesProps'
import { roundUpBar } from '../../utils/Utils'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const CategorySalesChart: React.FC<CategorySalesChartProps> = (props) => {
  const theme = useTheme()

  const preSalesList: CategorySalesDataProps[] =
    props.categorySalesList.previous
  const curSalesList: CategorySalesDataProps[] = props.categorySalesList.current

  const labels: string[] = []
  const preChartData: number[] = []
  const curChartData: number[] = []

  for (let i = 0; i < preSalesList.length; i++) {
    labels.push(preSalesList[i].category.categoryName)
    preChartData.push(preSalesList[i].sales)
    curChartData.push(curSalesList[i].sales)
  }

  const maxValue = Math.max(...preChartData, ...curChartData)

  const data = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: '前年売上',
        backgroundColor: theme.palette.barChart.light,
        data: preChartData,
        borderColor: 'white',
        borderWidth: 2,
        yAxisID: 'y',
      },
      {
        type: 'bar' as const,
        label: '当年売上',
        backgroundColor: theme.palette.barChart.main,
        data: curChartData,
        yAxisID: 'y',
      },
    ],
  }

  const options: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: roundUpBar(maxValue),
        // ticks: {
        //   callback: function (value: number) {
        //     return value.toLocaleString()
        //   },
        // },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  }

  return (
    <Box height={300}>
      <Chart type="bar" data={data} options={options} />
    </Box>
  )
}

export default CategorySalesChart
