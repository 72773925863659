import axios from 'axios'

const Logout = async () => {
  try {
    const isDebugMode = process.env.NODE_ENV === 'development' // 環境を切り替える条件

    const apikey = isDebugMode
      ? process.env.REACT_APP_API_KEY_DEBUG
      : process.env.REACT_APP_API_KEY
    console.log(apikey)

    const apiEndpoint = isDebugMode
      ? process.env.REACT_APP_API_GATEWAY_ENDPOINT_DEBUG
      : process.env.REACT_APP_API_GATEWAY_ENDPOINT

    const apiGatewayUrl = `${apiEndpoint}/logout`
    console.log(apiGatewayUrl)

    // POSTリクエストを送信
    const response = await axios.post(
      apiGatewayUrl,
      {},
      {
        headers: {
          'Content-Type': 'application/json', // JSONデータを送信するためのヘッダー
          'x-api-key': apikey, // APIキーを指定
        },
        withCredentials: true,
      }
    )

    if (response.status === 200) {
      // レスポンスが成功した場合の処理
      // console.log('Cookie確認完了:', response.data)
      return true // useNavigateで画面遷移
    } else {
      // エラーが発生した場合の処理
      //   throw new Error(`単品売上取得処理エラー: ${response.statusText}`)
      return false
    }
  } catch (error) {
    // 例外が発生した場合のエラーハンドリング
    console.error('Cookie確認処理エラー:', error)
    return false
  }
}

export default Logout
