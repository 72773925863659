import { useMediaQuery } from '@mui/material'

const useDeviceType = () => {
  const isMobile = useMediaQuery('(max-width:600px)')
  const isTablet = useMediaQuery('(min-width:601px) and (max-width:1180px)')
  const isPC = useMediaQuery('(min-width:1181px)')

  if (isMobile) return 'mobile'
  if (isTablet) return 'tablet'
  return 'pc'
}

export default useDeviceType
