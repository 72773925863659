import React, { createContext, ReactNode, useContext, useState } from 'react'

// Contextの型
interface UserContextType {
  userId: string
  setUserId: (id: string) => void
}

// デフォルト値の設定
const UserContext = createContext<UserContextType>({
  userId: '',
  setUserId: () => {},
})

// UserProviderを作成
export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<string | string>('')
  return (
    <UserContext.Provider value={{ userId, setUserId }}>
      {children}
    </UserContext.Provider>
  )
}

// useUserContextフックを作成して簡単にContextにアクセスできるようにする
export const useUserContext = () => useContext(UserContext)
