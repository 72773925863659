import React, { createContext, ReactNode, useContext, useState } from 'react'

// Contextの型
interface ContractContextType {
  contractId: string
  setContractId: (id: string) => void
}

// デフォルト値の設定
const ContractContext = createContext<ContractContextType>({
  contractId: '',
  setContractId: () => {},
})

// ContractProviderを作成
export const ContractProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [contractId, setContractId] = useState<string | string>('')
  console.log('ContractContext')
  return (
    <ContractContext.Provider value={{ contractId, setContractId }}>
      {children}
    </ContractContext.Provider>
  )
}

// useContractContextフックを作成して簡単にContextにアクセスできるようにする
export const useContractContext = () => useContext(ContractContext)
