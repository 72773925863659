import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import fetchProductSalesData from '../../api/ProductSalesAPI'
import fetchSalesFactorizationData from '../../api/SalesFactorizationAPI'
import fetchSalesTransactionData from '../../api/SalesTransitionAPI'
import ProductSales from '../../charts/ProductSales/ProductSales'
import SalesFactorization from '../../charts/SalesFactorization/SalesFactorization'
import SalesTransition from '../../charts/SalesTransition/SalesTransition'
import LoadingAnimation from '../../components/LoadingAnimation'
import { DashBoardScreenProps } from '../../props/DashBoardSceenProps'
import {
  ProductSalesDataProps,
  ProductSalesTableProps,
} from '../../props/ProductSalesProps'
import { APIRequestProps } from '../../props/ResponseProps'
import { SalesFactorizationListProps } from '../../props/SalesFactorizationProps'
import { SalesTransitionListProps } from '../../props/SalesTransitionProps'

const DaylyScreenContent: React.FC<DashBoardScreenProps> = (props) => {
  const requestProps: APIRequestProps = {
    contractId: props.contractId,
    dtddate: props.dtddate,
    dateType: props.dateType,
    storeList: props.storeList,
  }
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  // 売上因数分解取得
  const [salesFactorizationData, setSalesFactorizationData] =
    useState<SalesFactorizationListProps | null>(null)

  const fetchSalesFactorizationDataFromAPI = async () => {
    const result: SalesFactorizationListProps =
      await fetchSalesFactorizationData(requestProps)
    setSalesFactorizationData(result)
    return result
  }

  // 日別実績取得
  const [salesTransitionData, setSalesTransitionData] =
    useState<SalesTransitionListProps | null>(null)
  const fetchSalesTransitionDataFromAPI = async () => {
    const result: SalesTransitionListProps = await fetchSalesTransactionData(
      requestProps
    )
    setSalesTransitionData(result)
    return result
  }

  // 単品売上
  const [productSalesData, setProductSalesData] = useState<
    ProductSalesDataProps[]
  >([])
  const fetchProductSalesDataFromAPI = async () => {
    const result: ProductSalesTableProps = await fetchProductSalesData({
      contractId: props.contractId,
      dtddate: props.dtddate, // 日付は任意の値に変更
      dateType: props.dateType, // 任意の指定
      storeList: props.storeList,
    })
    setProductSalesData(result.productSalesList)
  }

  // データ取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setError(null)
        const results = await Promise.all([
          fetchSalesFactorizationDataFromAPI(),
          fetchSalesTransitionDataFromAPI(),
          fetchProductSalesDataFromAPI(),
        ])
        console.log('results:', results)
      } catch (err: unknown) {
        setError(
          err instanceof Error ? err.message : 'An unknown error occurred'
        )
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [props])

  if (loading) {
    return <LoadingAnimation /> // ローディング表示
  }

  if (error) {
    return <div>{error}</div> // エラーメッセージ表示
  }

  return (
    <Box display="flex" flexDirection="column">
      {/* チャート */}
      <Box paddingY={4} paddingX={2.5} sx={{ overflowY: 'auto' }}>
        {/* 実績確認セクション */}
        <SalesFactorization
          dateType={props.dateType}
          data={salesFactorizationData!}
        />
        <Box height={15} /> {/* Spacer */}
        {/* 売上推移 */}
        <SalesTransition
          dateType={props.dateType}
          data={salesTransitionData!}
        />
        <Box height={15} /> {/* Spacer */}
        {/* 単品売上 */}
        <ProductSales
          dateType={props.dateType}
          categoryList={props.categoryList}
          data={productSalesData}
        />
      </Box>
    </Box>
  )
}

export default DaylyScreenContent
