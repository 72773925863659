// smaregiAuth.ts
export const redirectToSmaregiAuth = (): void => {
  console.log('スマレジログイン')

  // 環境に応じた設定
  const isDebugMode = process.env.NODE_ENV === 'development' // 環境を切り替える条件
  const endpoint = isDebugMode
    ? process.env.REACT_APP_SMAREGI_AUTH_ENDPOINT_DEBUG
    : process.env.REACT_APP_SMAREGI_AUTH_ENDPOINT

  const clientId = isDebugMode
    ? process.env.REACT_APP_SMAREGI_CLIENT_ID_DEBUG
    : process.env.REACT_APP_SMAREGI_CLIENT_ID

  const redirectUri = isDebugMode
    ? process.env.REACT_APP_REDIRECT_URI_DEBUG
    : process.env.REACT_APP_REDIRECT_URI

  if (!clientId || !redirectUri) {
    console.error('Client ID または Redirect URI が設定されていません。')
    return
  }

  // スコープとレスポンスタイプの設定
  const scope =
    'openid email profile offline_access pos.transactions:read pos.products:read pos.stores:read'
  const responseType = 'code'

  // 認可エンドポイントURLを構築
  const authorizationUrl = `${endpoint}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(scope)}`

  console.log(authorizationUrl)

  // 認可エンドポイントにリダイレクト
  window.location.href = authorizationUrl
}
