import Box from '@mui/material/Box'
// import MenuItem from '@mui/material/MenuItem'
// import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
// import FormControl from '@mui/material/FormControl'
// import InputLabel from '@mui/material/InputLabel'
// import ListItemText from '@mui/material/ListItemText'
// import MenuItem from '@mui/material/MenuItem'
// import Select, { SelectChangeEvent } from '@mui/material/Select'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import { StoreProps, StoreSelectorProps } from '../props/StoreProps'

const StoreSelector: React.FC<StoreSelectorProps> = (props) => {
  const theme = useTheme()
  const storeList: StoreProps[] = [...props.storeList]
  const selectAll: StoreProps = { storeId: 'all', storeName: '全店舗' }
  // デフォルト全選択
  const [selectedList, setSelectedList] = useState<StoreProps[]>([...storeList])

  const filterOptions = createFilterOptions({
    stringify: (option: StoreProps) => option.storeId + option.storeName,
  })

  const handleChange = (
    event: React.SyntheticEvent,
    value: StoreProps[] | null
  ) => {
    if (value !== null) {
      const selectAll = value.some((element) => element.storeId === 'all')
      if (selectAll) {
        // 全選択と全選択解除の切り替え
        setSelectedList(value.length - 1 === storeList.length ? [] : storeList)
      } else {
        setSelectedList(value)
      }
    } else {
      setSelectedList([])
    }
  }

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      paddingX={1.5} // 必要に応じてパディングを調整
    >
      <Autocomplete
        options={storeList}
        value={selectedList}
        multiple
        disableCloseOnSelect
        limitTags={1}
        getOptionLabel={(option) => option.storeName}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            label="店舗"
            // placeholder="店舗名/店舗IDを検索"
            sx={{
              width: '200px',
              '& .MuiOutlinedInput-root': { maxHeight: '40px' },
            }}
          />
        )}
        // renderTags={() => null}
        renderOption={(props, option, { selected }) => {
          const { key, ...rest } = props
          return (
            <li key={key} {...rest}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={
                  option.storeId === 'all'
                    ? storeList.length === selectedList.length
                    : selected
                }
              />
              {option.storeName}
            </li>
          )
        }}
        isOptionEqualToValue={(option, value) => {
          return option.storeId === value.storeId
        }}
        filterOptions={(options, params) => {
          const filter = filterOptions
          const filtered = filter(options, params)
          return [selectAll, ...filtered]
        }}
        onChange={(event, value) => {
          handleChange(event, value)
        }}
        onClose={(event, reason) => {
          props.onSelectionChange(selectedList.map((store) => store.storeId))
        }}
        sx={{ ml: 1 }}
      />
    </Box>
  )
}

export default StoreSelector
