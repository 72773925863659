import BarChartIcon from '@mui/icons-material/BarChart'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PolicyIcon from '@mui/icons-material/Policy'
import StoreIcon from '@mui/icons-material/Store'
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/title_logo.png'
import CustomListItem from '../../components/CustomListItem'
import LogoutButton from '../../components/LogoutButton'
import MarginBox from '../../components/MarginBox'
import { useContractContext } from '../../contexts/ContractContext'
import { useUserContext } from '../../contexts/UserContext'
import { drawerWidth } from '../../utils/Utils'
import DailyScreen from '../Daily/DailyScreen'
import MonthlyScreen from '../Monthly/MonthlyScreen'

const HomeScreenContent: React.FC = () => {
  const theme = useTheme()

  const [selectedMenu, setSelectedMenu] = useState<number>(0)
  const navigate = useNavigate()
  const { contractId } = useContractContext()
  const { userId } = useUserContext()

  const handleMenuClick = (index: number) => {
    setSelectedMenu(index)
  }

  useEffect(() => {
    if (!contractId) {
      navigate('/') // contractIdが空の場合、ホーム画面に遷移
    }
  }) // contractIdの変更を監視

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar: 上部の固定ナビゲーションバー */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: theme.palette.primary.main,
        }}
      >
        <Toolbar disableGutters>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <img
              src={logo}
              alt="Logo"
              style={{ height: '70px', marginRight: '16px' }}
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                display="flex"
                flexDirection="column"
                paddingX={2}
                paddingY={0.5}
                sx={{
                  border: `1px solid`,
                  borderRadius: 1,
                  borderColor: theme.palette.background.default,
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  契約ID　　：{contractId}
                </Typography>
                <Divider
                  sx={{ borderColor: theme.palette.background.default }}
                />
                <Typography sx={{ fontSize: 14 }}>
                  ユーザーID：{userId}
                </Typography>
              </Box>
              <MarginBox height={0} width={10} />
              <LogoutButton />
              <MarginBox height={0} width={10} />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer: 左側の固定メニュー */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: theme.palette.secondary.main,
            display: 'flex',
            flexDirection: 'column', // 縦に並べる
            justifyContent: 'space-between',
          },
        }}
      >
        <Box>
          {/* 上部メニュー */}
          <Toolbar /> {/* AppBarと同じ高さのスペーサー */}
          <MarginBox height={10} width={0} />
          <List>
            <CustomListItem
              title="日次レポート"
              icon={<StoreIcon />}
              selected={selectedMenu === 0}
              onClick={() => handleMenuClick(0)}
            />

            <Divider variant="middle" component="li" />
            <CustomListItem
              title="月次レポート"
              icon={<BarChartIcon />}
              selected={selectedMenu === 1}
              onClick={() => handleMenuClick(1)}
            />
          </List>
        </Box>
        {/* 下部メニュー */}
        <Box>
          <List>
            <ListItemButton
              onClick={() => {
                window.open(process.env.REACT_APP_MANUAL_URL)
              }}
            >
              <ListItemIcon>
                <HelpOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="操作ガイド"
                primaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListItemButton>
            <Divider variant="middle" component="li" />
            <ListItemButton
              onClick={() => {
                window.open(process.env.REACT_APP_HELP_URL)
              }}
            >
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText
                primary="お問い合わせ"
                primaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListItemButton>
            <Divider variant="middle" component="li" />
            <ListItemButton
              onClick={() => {
                window.open(process.env.REACT_APP_TERMS_URL)
              }}
            >
              <ListItemIcon>
                <PolicyIcon />
              </ListItemIcon>
              <ListItemText
                primary="利用規約"
                primaryTypographyProps={{ fontWeight: 'bold' }}
              />
            </ListItemButton>
          </List>
        </Box>
      </Drawer>

      {/* メインコンテンツ */}
      <Box component="main" sx={{ background: '#F0F0F0' }}>
        <Toolbar /> {/* AppBarと同じ高さのスペーサー */}
        {/* コンテンツの表示 */}
        {selectedMenu === 0 && <DailyScreen />}
        {selectedMenu === 1 && <MonthlyScreen />}
        {/* {selectedMenu === 1 && <ItemRankScreen />} */}
        {/* 他のメニューのコンポーネントも条件に応じて追加 */}
      </Box>
    </Box>
  )
}

const HomeScreen: React.FC = () => <HomeScreenContent />

export default HomeScreen
