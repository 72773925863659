import React, { createContext, ReactNode, useContext, useState } from 'react'

// Contextの型
interface MenuToggleContextType {
  menuToggle: boolean
  setMenuToggle: (exists: boolean) => void
}

// デフォルト値の設定
const MenuToggleContext = createContext<MenuToggleContextType>({
  menuToggle: true,
  setMenuToggle: () => {},
})

// ContractProviderを作成
export const MenuToggleProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(true)
  return (
    <MenuToggleContext.Provider value={{ menuToggle, setMenuToggle }}>
      {children}
    </MenuToggleContext.Provider>
  )
}

// useContractContextフックを作成して簡単にContextにアクセスできるようにする
export const useMenuToggleContext = () => useContext(MenuToggleContext)
