import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom' // useNavigateに変更
import dataLinkage from '../../api/DataLinkageAPI'
import { useContractContext } from '../../contexts/ContractContext'

const TermsScreen: React.FC = () => {
  const navigate = useNavigate() // useHistoryではなくuseNavigateを使用
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { contractId } = useContractContext()

  useEffect(() => {
    if (!contractId) {
      navigate('/') // contractIdが空の場合、ホーム画面に遷移
    }
  })

  // チェックボックスの状態変更
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  // 同意ボタンのクリックハンドラー
  const handleAgree = async () => {
    // 同意後、データ連携処理へ
    try {
      setLoading(true)
      const result = await dataLinkage(contractId)
      if (result === 'SUCCESS') {
        navigate('/home')
      }
    } catch (error) {
      setError('データ取得中にエラーが発生しました')
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return <div>データ連携中...</div>
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        利用規約
      </Typography>
      <Typography variant="body1">
        {/* 利用規約の内容をここに記載 */}
        この規約は、サービスを利用する上での基本的なルールを定めたものです。
        必ず内容をよく読み、同意する場合のみ「同意する」ボタンをクリックしてください。
      </Typography>
      <Box
        sx={{
          maxHeight: 300,
          overflow: 'auto',
          border: '1px solid #ccc',
          padding: 2,
          mb: 2,
        }}
      >
        <Typography variant="body1">
          {/* 利用規約の内容をここに記載 */}
          データミル利用規約
          本利用規約（以下「本規約」といいます）は、データコム株式会社（以下「当社」といいます）が提供するデータミル（以下「本サービス」といいます）の利用に関する条件を定めるものです。ユーザーは本規約に同意したうえで、本サービスを利用するものとします。
          第1条（適用）
          本規約は、ユーザーと当社との間の本サービスの利用に関する一切の関係に適用されます。
          当社は、必要に応じて本規約の変更を行うことがあります。本規約の変更について、変更後の規約が適用される日までに、当社のウェブサイト上またはその他の適切な方法でユーザーに通知します。ユーザーが規約変更後も本サービスの利用を継続した場合、当該変更に同意したものとみなされます。
          第2条（定義）
          本規約において使用する用語の定義は、以下のとおりとします。
          「ユーザー」とは、本サービスを利用するために登録した事業者をいいます。
          「スマレジ」とは、株式会社スマレジが提供するクラウドPOSシステムをいいます。
          「スマレジAPI」とは、スマレジのデータにアクセスするためのアプリケーション・プログラミング・インターフェースをいいます。
          第3条（利用登録）
          本サービスの利用を希望する者は、本規約に同意の上、当社が定める方法により利用登録を申請するものとします。
          当社は、利用登録の申請者に対し、当社の基準に従って利用登録を承認または拒否することがあります。承認されなかった場合、その理由を説明する義務を負いません。
          第4条（利用料金および支払方法）
          本サービスの利用に際しては、当社が別途定める利用料金が発生します。サービス利用料金や支払方法については、当社が定めるところに従うものとします。
          サービス利用料金の支払いが滞った場合、当社はユーザーに対して本サービスの利用停止または利用の解除を行うことができます。
          第5条（データの取り扱い）
          本サービスは、スマレジAPIを通じてスマレジの取引データを自動的に取得します。
          本サービスは、ユーザーの取引データを基に経営改善に役立つ情報を提供します。
          当社は、取得したデータを統計的に処理し、ユーザーまたは第三者に対して市場データとして提供することがあります。この際、データは個別の事業者や取引を特定できない形式で使用され、事業者の営業上の重要な情報は適切に保護されます。
          当社は、取得した取引データを活用し、経営改善の一環として、資金調達やキャッシュフローの改善に関わるサービスを提供する場合があります。この場合、サービス提供に必要な範囲内で、当社が定める提携先に対して、ユーザーが事業者として特定できる形式で情報を提供することがあります。
          当社は、将来的に他の当社が提供するサービスまたは当社が定める提携先のサービスにおいても、取得したデータを活用できるものとします。この際、それぞれのサービスに必要な範囲内で、事業者を特定できる形式で情報を提供することがあります。ユーザーは、当社が別途定める手続きに従い、いつでもこの同意を撤回することができます。同意撤回が行われた場合、当社はサービスの一部または全部の提供を停止する権利を有します。
          当社は、本サービスを提供するにあたり、個人情報保護法、独占禁止法、その他の関連する法律および規制を遵守します。また、ユーザーの取引データの利用は、これらの法規に基づいて行われるものとします。
          当社は、ユーザーの取引データおよびその他のデータの保護に合理的なセキュリティ対策を講じますが、これらの対策にもかかわらず発生したデータの不正アクセス、漏洩、改ざん、紛失等に関しては、当社は一切の責任を負わないものとします。
          附則
          本規約への同意をもって、ユーザーは本条に定めるデータの取り扱いおよび利用に同意したものとみなされます。
          第6条（反社会勢力の排除）
          ユーザーおよび当社は、次の各号について表明し、保証します。
          自らの役員に暴力団、暴力団関係企業、総会屋等の反社会的勢力（以下「反社会的勢力」といいます）の構成員がいないこと。
          反社会的勢力の構成員が自らの経営に実質的に関与していないこと。
          反社会的勢力に対して資金を提供または便宜を供与する等、反社会的勢力の維持運営に協力、関与していないこと。
          自らの役員が反社会的勢力と社会的に非難されるべき関係を有していないこと。
          ユーザーおよび当社は、自らがまたは第三者を利用して次の各号に該当する行為を行わないことを保証します。
          脅迫的な言動または暴力行為。 法的な責任を超えた不当な要求行為。
          風説を流布し偽計または威力を用いて相手方の信用を毀損する行為。
          相手方の業務を妨害する行為。
          ユーザーまたは当社が前二項に違反した場合、相手方に通告することなく、本サービスの利用を解除することができるものとします。
          本条に基づき利用を解除した場合でも、当該解除により生じた損害について、当社は一切の責任を負わないものとします。
          第7条（禁止事項）
          ユーザーは、本サービスの利用に際して以下の行為を行ってはならないものとします。
          法令または公序良俗に違反する行為 本サービスの不正利用
          本サービスを通じて取得した情報を無断で第三者に提供または販売する行為
          当社、他のユーザー、または第三者に対する誹謗中傷、名誉毀損、営業秘密や機密情報の侵害等の行為
          本サービスのリバースエンジニアリング、逆コンパイル、逆アセンブル、修正、改変、翻訳、ソースコードの解析、または本サービスの派生ソフトウェアの作成。
          本サービスに関するドキュメントの無断複製 第13条（当社による解除）
          ユーザーが、本規約の条項または条件のいずれかに違反した場合、当社はユーザーへの催告なく、即時に本規約に基づく本サービスの利用を停止することができます。
          第14条（本サービスの提供の停止等）
          当社は、以下の場合にユーザーへの事前通知なく、本サービスの全部または一部の提供を停止または中断することができます。
          本サービスに関わるシステムの保守または点検を行う場合
          災害、停電、通信障害、その他の不可抗力により本サービスの提供が困難な場合
          第15条（保証および免責事項）
          当社は、本サービスの機能やパフォーマンスがユーザーの要求を満たすこと、またはユーザーの環境において正常に作動することを保証しません。
          本サービスは、ユーザーの準備した環境において永続的に使用できることを保証するものではなく、また、バグが存在した場合、それが修正されることを保証するものではありません。
          当社は、本サービスに関する情報、分析結果、レポートの、正確性、適時性、有用性、信頼性について保証せず、これに基づいて行われたユーザーの決定や行為に対して一切の責任を負いません。
          当社は、事前の通知なく本サービスの機能や付随するサービスを変更または中止することがあります。これにより、ユーザーは本契約締結時における使用環境が永続することを期待できません。
          当社は、本サービスの利用に関連して生じた損害（直接的、間接的を問わず）について、一切の責任を負わないものとします。ただし、当社が故意または重過失によって損害を発生させた場合に限り、当社が負う賠償責任の総額は、当該損害が発生した月にユーザーが支払った本サービスの利用料金を上限とします。
          当社は、ユーザーに係る一切のデータのバックアップを行う義務を負わないものとします。ユーザーが必要とする場合、自己の費用と責任でバックアップを行うものとします。
          本サービスの利用期間の満了または解約、解除等により本規約に基づくサービスの利用が終了した場合、当社はユーザーに係る一切のデータを削除します。ユーザーは、契約終了前に必要なデータをダウンロードするなどして取得してください。
          当社による助言や情報提供（口頭やEメール等の文書によるものを含む）は、いかなる場合においても本規約に定める保証の制限の範囲を拡大するものではありません。
          第16条（サービスの終了）
          当社は、ユーザーに対して30日前までに通知した上で、本サービスの全部または一部を終了することができます。サービス終了に際し、ユーザーが必要なデータを取得するための期間を提供します。
          第17条（準拠法および裁判管轄） 本規約は日本法に準拠します。
          本サービスに関して生じた紛争については、当社の本社所在地を管轄する裁判所を専属的合意管轄とします。
        </Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
        }
        label="利用規約を確認しました"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAgree}
        disabled={!isChecked} // チェックボックスがオンでない場合はボタンを無効化
      >
        同意する
      </Button>
    </Container>
  )
}

export default TermsScreen
