// src/components/Title.tsx
import React from 'react';
import { Typography } from '@mui/material';

interface TitleProps {
  text: string;
}

const CommonTitle: React.FC<TitleProps> = ({ text }) => {
  return (
    <Typography 
      variant="h5" 
      component="h2" 
      style={{ color: 'text.secondary', fontWeight: 'bold', marginBottom: '5px' }}
    >
      {text}
    </Typography>
  );
};

export default CommonTitle;
