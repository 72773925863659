// src/components/CommonContents.tsx
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/material/styles'
import React from 'react'

interface CustomListItemProps {
  title: string
  icon: React.ReactNode
  selected: boolean
  onClick: () => void
}

const CustomListItem: React.FC<CustomListItemProps> = ({
  title,
  icon,
  selected,
  onClick,
}) => {
  const theme = useTheme()
  return (
    <ListItem>
      <ListItemButton
        selected={selected}
        onClick={onClick}
        sx={{
          backgroundColor: selected
            ? theme.palette.primary.main
            : theme.palette.background.default,
          border: '2px solid',
          borderColor: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: selected
              ? theme.palette.primary.dark // 選択時かつホバー時の色
              : theme.palette.action.hover, // 通常のホバー時の色
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main, // 選択時の背景色
            '&:hover': {
              backgroundColor: theme.palette.primary.dark, // 選択状態のホバー時
            },
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 30,
            color: selected
              ? theme.palette.background.default
              : theme.palette.text.primary,
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ fontWeight: 'bold' }}
          sx={{
            color: selected
              ? theme.palette.background.default
              : theme.palette.text.primary,
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default CustomListItem
