import React, { createContext, ReactNode, useContext, useState } from 'react'

// Contextの型
interface ContractContextType {
  cookieExists: boolean
  setCookieExists: (exists: boolean) => void
}

// デフォルト値の設定
const CookieExistsContext = createContext<ContractContextType>({
  cookieExists: false,
  setCookieExists: () => {},
})

// ContractProviderを作成
export const CookieExistsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cookieExists, setCookieExists] = useState<boolean>(false)
  console.log('ContractContext')
  return (
    <CookieExistsContext.Provider value={{ cookieExists, setCookieExists }}>
      {children}
    </CookieExistsContext.Provider>
  )
}

// useContractContextフックを作成して簡単にContextにアクセスできるようにする
export const useCookieExistsContext = () => useContext(CookieExistsContext)
