// src/components/CommonContents.tsx
import { Box, Card, CardContent } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import { drawerWidth } from '../utils/Utils'
import CommonTitle from './CommonTitle'

interface CommonContentsProps {
  content: React.ReactNode
  title?: string
}

const CommonContents: React.FC<CommonContentsProps> = ({ content, title }) => {
  const theme = useTheme()
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      // justifyContent="flex-start"
      width={`calc(100vw - ${drawerWidth + 60}px)`} // 画面の横幅全体に広げる
      padding={0} // 必要に応じてパディングを調整
      margin={0} // 必要に応じてマージンを調整
    >
      {title && (
        <>
          <CommonTitle text={title} />
        </>
      )}
      <Card sx={{ width: '100%' }}>
        <CardContent>{content}</CardContent>
      </Card>
    </Box>
  )
}

export default CommonContents
