import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom' // useNavigateに変更
import fetchCheckCookie from '../../api/CheckCookie'
import { redirectToSmaregiAuth } from '../../api/SmaregiAuth'
import { useCookieExistsContext } from '../../contexts/CookieExistsContext'

const AuthScreen: React.FC = () => {
  const navigate = useNavigate() // useHistoryではなくuseNavigateを使用
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const { cookieExists, setCookieExists } = useCookieExistsContext()

  useEffect(() => {
    const fetchCheckCookieFromAPI = async () => {
      try {
        setError(null)
        setLoading(true)
        const result = await fetchCheckCookie()
        console.log('Cookie：', result)
        setCookieExists(result)
        if (result) {
          console.log('スマレジログインスキップ')
          navigate('/callback') // useNavigateを使用して遷移
        } else {
          redirectToSmaregiAuth()
        }
      } catch (error) {
        setError('データ取得中にエラーが発生しました')
      } finally {
        setLoading(false)
      }
    }
    fetchCheckCookieFromAPI()
  }, [])

  return <div>Redirecting to Smaregi login...</div>
}

export default AuthScreen
