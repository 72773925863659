import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import React from 'react'
import { ProductSalesTableProps } from '../../props/ProductSalesProps'

const ProductSalesGrid: React.FC<ProductSalesTableProps> = (props) => {
  const theme = useTheme()

  function renderCell(params: GridRenderCellParams, unit: string): JSX.Element {
    const value = params.value as number | null
    let textcolor: string
    let strvalue: string
    let sign: string
    if (value !== null) {
      strvalue = Number(value.toFixed(0)).toLocaleString()
      if (value > 0) {
        sign = '+'
        textcolor = theme.palette.info.light
      } else if (value < 0) {
        sign = ''
        textcolor = theme.palette.error.main
      } else {
        sign = '±'
        textcolor = theme.palette.text.primary
      }
    } else {
      sign = ''
      strvalue = '-'
      textcolor = theme.palette.text.primary
    }
    return (
      <span
        style={{
          color: textcolor,
        }}
      >
        {sign}
        {strvalue}
        {unit}
      </span>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'productCode',
      headerName: '商品コード',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'productName',
      headerName: '商品',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'sales',
      headerName: '売上',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const value = params.value as number
        return <span>{value.toLocaleString()}</span>
      },
    },
    {
      field: 'salesRatio',
      headerName: props.dateType === '0' ? '前週比' : '前年比',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderCell(params, '%'),
    },
    {
      field: 'salesDiff',
      headerName: '差額',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderCell(params, ''),
    },
    {
      field: 'quantity',
      headerName: '点数',
      width: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'quantityRatio',
      headerName: props.dateType === '0' ? '前週比' : '前年比',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderCell(params, '%'),
    },
    {
      field: 'quantityDiff',
      headerName: '点差',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderCell(params, ''),
    },
  ]

  const rows = []
  for (const product of props.productSalesList) {
    rows.push({
      id: product.productId,
      productCode: product.productCode,
      productName: product.productName,
      sales: product.sales,
      salesRatio: product.salesRatio,
      salesDiff: product.salesDiff,
      quantity: product.quantity,
      quantityRatio: product.quantityRatio,
      quantityDiff: product.quantityDiff,
    })
  }
  // console.log('rows', rows)
  return (
    <Box height={600}>
      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        hideFooter
        initialState={{
          sorting: {
            sortModel: [{ field: 'sales', sort: 'desc' }], // 売上列を降順にソート
          },
        }}
        pageSizeOptions={[-1]}
        localeText={{
          noRowsLabel: '登録されている商品はありません', // カスタムメッセージ
          // MuiTablePagination: {
          //   labelRowsPerPage: '表示数', // Rows per page 変更
          //   labelDisplayedRows: ({from, to, count}) =>
          // },
        }}
      />
    </Box>
  )
}

export default ProductSalesGrid
