import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import React from 'react'

const ComparisonText: React.FC<{
  value: number | null
  unit: string
  decimal: boolean
}> = ({ value, unit, decimal }) => {
  const theme = useTheme()

  let strvalue: string
  let textcolor: string
  let sign: string
  let arrow: string

  if (value === null) {
    strvalue = '-'
    sign = ''
    textcolor = theme.palette.text.primary
    arrow = ''
  } else {
    strvalue = decimal
      ? Math.abs(value).toFixed(1).toLocaleString()
      : Math.abs(Math.round(value)).toLocaleString()
    if (value > 0) {
      sign = '+'
      arrow = ' ▲'
      textcolor = theme.palette.info.light
    } else if (value === 0) {
      sign = '±'
      textcolor = theme.palette.text.primary
      arrow = ''
    } else {
      sign = '-'
      arrow = ' ▼'
      textcolor = theme.palette.error.main
    }
  }
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="caption" sx={{ color: textcolor }}>
        {sign}
        {strvalue}
        {unit}
      </Typography>
      <Typography sx={{ fontSize: 10, color: textcolor }}>{arrow}</Typography>
    </Box>
  )
}

export default ComparisonText
