import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CategorySelector from '../../components/CategorySelector'
import MarginBox from '../../components/MarginBox'
import { CategoryProps, CategorySelectorProps } from '../../props/CategoryProps'
import {
  ProductSalesContentProps,
  ProductSalesDataProps,
} from '../../props/ProductSalesProps'
import { noSelectCategory } from '../../utils/Utils'
import ProductSalesGrid from './ProductSalesGrid'

const ProductSalesContent: React.FC<ProductSalesContentProps> = (props) => {
  // チャートに表示するリスト
  const [productList, setProductList] = useState<ProductSalesDataProps[]>(
    props.productSalesList
  )
  // 1階層目のカテゴリー
  const category1List: CategoryProps[] = [noSelectCategory]
  category1List.push(
    ...props.categoryList.filter((category) => category.level === 1)
  )
  // ２階層目のカテゴリー
  const [category2List, setCategory2List] = useState<CategoryProps[]>([
    noSelectCategory,
  ])
  // ３階層目のカテゴリー
  const [category3List, setCategory3List] = useState<CategoryProps[]>([
    noSelectCategory,
  ])

  // 1階層目の選択したカテゴリー
  const [selectedCategory1, setSelectedCategory1] = useState<string>(
    noSelectCategory.categoryId
  )
  // ２階層目の選択したカテゴリー
  const [selectedCategory2, setSelectedCategory2] = useState<string>(
    noSelectCategory.categoryId
  )
  // ３階層目の選択したカテゴリー
  const [selectedCategory3, setSelectedCategory3] = useState<string>(
    noSelectCategory.categoryId
  )

  /*
   * １階層目のカテゴリを選択したときの処理
   * selectedCategory1のステートを監視
   */
  useEffect(() => {
    // console.log('useEffect1')
    const newCategoryList: CategoryProps[] = [noSelectCategory]
    const newProductList: ProductSalesDataProps[] = []
    if (selectedCategory1 !== noSelectCategory.categoryId) {
      /*
      1階層目で選択した部門の
      2階層目の部門一覧作成
    */
      newCategoryList.push(
        ...props.categoryList.filter(
          (category) =>
            category.level === 2 && category.categoryId1 === selectedCategory1
        )
      )
      /*
      1階層目で選択部門に属する
      商品一覧作成
    */
      newProductList.push(
        ...props.productSalesList.filter(
          (product) => product.categoryId1 === selectedCategory1
        )
      )
    } else {
      // 未選択の場合、全単品表示
      newProductList.push(...props.productSalesList)
    }
    setCategory2List(newCategoryList)
    setCategory3List([noSelectCategory]) // 三階層目はリセット
    setProductList(newProductList)
  }, [selectedCategory1])

  /*
   * ２階層目のカテゴリを選択したときの処理
   * selectedCategory2のステートを監視
   */
  useEffect(() => {
    // console.log('useEffect2')
    if (selectedCategory1 !== noSelectCategory.categoryId) {
      const newCategoryList: CategoryProps[] = [noSelectCategory]
      const newProductList: ProductSalesDataProps[] = []

      if (selectedCategory2 !== noSelectCategory.categoryId) {
        /*
      2階層目で選択した部門に属する
      3階層目の部門一覧作成
    */
        newCategoryList.push(
          ...props.categoryList.filter(
            (category) =>
              category.level === 3 &&
              category.categoryId1 === selectedCategory1 &&
              category.categoryId2 === selectedCategory2
          )
        )
        /*
      2階層目で選択した部門に属する
      商品一覧作成
    */
        newProductList.push(
          ...props.productSalesList.filter(
            (product) =>
              product.categoryId1 === selectedCategory1 &&
              product.categoryId2 === selectedCategory2
          )
        )
      } else {
        /*
      未選択の時は
      1階層目に属する商品一覧を作成
    */
        newProductList.push(
          ...props.productSalesList.filter(
            (product) => product.categoryId1 === selectedCategory1
          )
        )
      }
      setCategory3List(newCategoryList)
      setProductList(newProductList)
    }
  }, [selectedCategory2])

  /*
   * ３階層目のカテゴリを選択したときの処理
   * selectedCategory３のステートを監視
   */
  useEffect(() => {
    // console.log('useEffect3')
    const newProductList: ProductSalesDataProps[] = []
    if (
      selectedCategory1 !== noSelectCategory.categoryId &&
      selectedCategory2 !== noSelectCategory.categoryId
    ) {
      if (selectedCategory3 !== noSelectCategory.categoryId) {
        /*
      3階層目で選択した部門に属する
      商品一覧作成
    */
        newProductList.push(
          ...props.productSalesList.filter(
            (product) =>
              product.categoryId1 === selectedCategory1 &&
              product.categoryId2 === selectedCategory2 &&
              product.categoryId3 === selectedCategory3
          )
        )
      } else {
        /*
      未選択の時は
      1階層目に属する商品一覧を作成
    */
        newProductList.push(
          ...props.productSalesList.filter(
            (product) =>
              product.categoryId1 === selectedCategory1 &&
              product.categoryId2 === selectedCategory2
          )
        )
      }
      setProductList(newProductList)
    }
  }, [selectedCategory3])

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start" // 水平方向に左揃え
        alignItems="center" // 垂直方向を中央揃え
        // sx={{ backgroundColor: 'red' }}
      >
        <CategorySelectBox
          label="階層レベル１"
          categoryList={category1List}
          selectedCategory={selectedCategory1}
          onSelectionChange={(category) => {
            if (category) {
              setSelectedCategory1(category)
              // 下の階層はリセット
              setSelectedCategory2(noSelectCategory.categoryId)
              setSelectedCategory3(noSelectCategory.categoryId)
            }
          }}
        />
        <MarginBox height={0} width={5} />
        <CategorySelectBox
          label="階層レベル２"
          categoryList={category2List}
          selectedCategory={selectedCategory2}
          onSelectionChange={(category) => {
            if (category) {
              setSelectedCategory2(category)
              // 下の階層はリセット
              setSelectedCategory3(noSelectCategory.categoryId)
            }
          }}
          disabled={selectedCategory1 === noSelectCategory.categoryId}
        />
        <MarginBox height={0} width={5} />
        <CategorySelectBox
          label="階層レベル３"
          categoryList={category3List}
          selectedCategory={selectedCategory3}
          onSelectionChange={(category) => {
            if (category) {
              setSelectedCategory3(category)
            }
          }}
          disabled={selectedCategory2 === noSelectCategory.categoryId}
        />
      </Box>
      <MarginBox height={10} width={0} />
      {/* チャートを追加 */}
      {/* <Box height={600}> */}
      <ProductSalesGrid
        dateType={props.dateType}
        productSalesList={productList}
      />
      {/* </Box> */}
    </Box>
  )
}

const CategorySelectBox: React.FC<CategorySelectorProps> = (props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start" // 水平方向に左揃え
      alignItems="center" // 垂直方向を中央揃え
    >
      <CategorySelector
        label={props.label}
        categoryList={props.categoryList}
        selectedCategory={props.selectedCategory}
        onSelectionChange={props.onSelectionChange}
        disabled={props.disabled}
      />
    </Box>
  )
}

// 単品売上一覧のコンポーネント

export default ProductSalesContent
