import { Box } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import React from 'react'
import { CategorySelectorProps } from '../props/CategoryProps'

const CategorySelector: React.FC<CategorySelectorProps> = (props) => {
  return (
    <Box>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="select-label">{props.label}</InputLabel>
        <Select
          labelId="select-label"
          label={props.label}
          value={props.selectedCategory}
          onChange={(event) => {
            props.onSelectionChange(event.target.value)
          }}
          disabled={props.disabled}
          sx={{
            textAlign: 'left',
            width: 200, // 幅を200pxに設定
            margin: '0 auto', // 中央寄せ
            backgroundColor: 'background.default', // 背景色を白に設定
            borderRadius: 2, // 角を少し丸める
            '& .MuiSelect-select': {
              padding: '8px 16px', // 内側の余白を設定
            },
            '&.Mui-selected': {
              backgroundColor: '#e0f7fa', // 選択時の背景色
              color: '#00796b', // 選択時のテキスト色
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#b2ebf2', // 選択された状態でホバー時の背景色
            },
          }}
        >
          {props.categoryList.map((x) => (
            <MenuItem
              key={x.categoryId}
              value={x.categoryId}
              sx={{
                padding: '8px 16px', // 各項目の内側の余白
                fontSize: '1rem', // フォントサイズを調整
                backgroundColor: 'background.default',
                '&:hover': {
                  backgroundColor: '#f0f0f0', // ホバー時の背景色
                },
              }}
            >
              {x.categoryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CategorySelector
