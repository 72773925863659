import { Box } from '@mui/material'
import React from 'react'
import EmptyBox from '../../components/EmptyBox'
import MarginBox from '../../components/MarginBox'
import { SalesFactorizationContentProps } from '../../props/SalesFactorizationProps'
import useDeviceType from '../../utils/UseDeviceType'
import { factorizationBoxHeight } from '../../utils/Utils'
import SalesFactorizationBox from './SalesFactorizationBox'

const marginHeight = 10
const lineWidth = 50

const SalesFactorizationContent: React.FC<SalesFactorizationContentProps> = (
  props
) => {
  const deviceType = useDeviceType()

  return (
    <Box
      display="flex"
      justifyContent={deviceType === 'pc' ? 'center' : 'flex-start'}
      alignItems="flex-start"
      sx={{ overflowX: 'auto' }}
    >
      {/* 1層目 */}
      <Box>
        <SalesFactorizationBox
          title="売上"
          dateType={props.dateType}
          data={props.data.sales}
          unit="円"
          decimal={false}
          borderType="solid"
        />
      </Box>

      {/* 線：売上→取引数・取引単価 */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            width: lineWidth,
            height: factorizationBoxHeight / 2,
            borderBottom: '3px solid',
          }}
        />
        <Box display="flex">
          <Box sx={{ width: lineWidth / 2 }} />
          <Box
            sx={{
              width: lineWidth / 2,
              height: factorizationBoxHeight + marginHeight,
              borderBottom: '3px solid',
              borderLeft: '3px solid',
            }}
          />
        </Box>
      </Box>

      {/* 2層目 */}
      <Box>
        <SalesFactorizationBox
          title="取引数"
          dateType={props.dateType}
          data={props.data.transactionCount}
          unit="回"
          decimal={false}
          borderType="solid"
        />
        <MarginBox height={marginHeight} width={0} />
        <SalesFactorizationBox
          title="取引単価"
          dateType={props.dateType}
          data={props.data.transactionUnitPrice}
          unit="円"
          decimal={false}
          borderType="solid"
        />
      </Box>

      {/* 線：取引単価→取引あたり客数・客単価 */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <EmptyBox height={factorizationBoxHeight} />
        <MarginBox height={marginHeight} width={0} />
        <Box
          sx={{
            width: lineWidth,
            height: factorizationBoxHeight / 2,
            borderBottom: '3px solid',
          }}
        />
        <Box display="flex">
          <Box sx={{ width: lineWidth / 2 }} />
          <Box
            sx={{
              width: lineWidth / 2,
              height: factorizationBoxHeight + marginHeight,
              borderBottom: '3px solid',
              borderLeft: '3px solid',
            }}
          />
        </Box>
      </Box>

      {/* 3層目 */}
      <Box>
        <SalesFactorizationBox
          title="客数"
          dateType={props.dateType}
          data={props.data.guest}
          unit="人"
          decimal={false}
          borderType="dashed"
        />
        <MarginBox height={marginHeight} width={0} />
        <SalesFactorizationBox
          title="客数 / 取引"
          dateType={props.dateType}
          data={props.data.guestPerTransaction}
          unit="人"
          decimal={true}
          borderType="solid"
        />
        <MarginBox height={marginHeight} width={0} />
        <SalesFactorizationBox
          title="客単価"
          dateType={props.dateType}
          data={props.data.guestUnitPrice}
          unit="円"
          decimal={false}
          borderType="solid"
        />
      </Box>

      {/* 線：客単価→一人あたり買い上げ点数・商品平均単価 */}
      <Box display="flex" flexDirection="column" alignItems="center">
        <EmptyBox height={factorizationBoxHeight} />
        <MarginBox height={marginHeight} width={0} />
        <EmptyBox height={factorizationBoxHeight} />
        <MarginBox height={marginHeight} width={0} />
        <Box
          sx={{
            width: lineWidth,
            height: factorizationBoxHeight / 2,
            borderBottom: '3px solid',
          }}
        />
        <Box display="flex">
          <Box sx={{ width: lineWidth / 2 }} />
          <Box
            sx={{
              width: lineWidth / 2,
              height: factorizationBoxHeight + marginHeight,
              borderBottom: '3px solid',
              borderLeft: '3px solid',
            }}
          />
        </Box>
      </Box>

      {/* 4層目 */}
      <Box>
        <EmptyBox height={factorizationBoxHeight} />
        <MarginBox height={marginHeight} width={0} />
        <EmptyBox height={factorizationBoxHeight} />
        <MarginBox height={marginHeight} width={0} />
        <SalesFactorizationBox
          title="買上点数 / 人"
          dateType={props.dateType}
          data={props.data.amountPerGuest}
          unit="点"
          decimal={true}
          borderType="solid"
        />
        <MarginBox height={marginHeight} width={0} />
        <SalesFactorizationBox
          title="商品平均単価"
          dateType={props.dateType}
          data={props.data.itemUnitPrice}
          unit="円"
          decimal={false}
          borderType="solid"
        />
      </Box>
    </Box>
  )
}
export default SalesFactorizationContent
