import axios from 'axios'
import { APIRequestProps } from '../props/ResponseProps'

const fetchSalesFactorizationData = async (params: APIRequestProps) => {
  try {
    const isDebugMode = process.env.NODE_ENV === 'development' // 環境を切り替える条件

    const apikey = isDebugMode
      ? process.env.REACT_APP_API_KEY_DEBUG
      : process.env.REACT_APP_API_KEY

    const apiEndpoint = isDebugMode
      ? process.env.REACT_APP_API_GATEWAY_ENDPOINT_DEBUG
      : process.env.REACT_APP_API_GATEWAY_ENDPOINT

    const apiGatewayUrl = `${apiEndpoint}/get-sales-factorization`

    // POSTリクエストを送信
    const response = await axios.post(apiGatewayUrl, params, {
      headers: {
        'Content-Type': 'application/json', // JSONデータを送信するためのヘッダー
        'x-api-key': apikey, // APIキーを指定
      },
    })

    if (response.status === 200) {
      // レスポンスが成功した場合の処理
      console.log('売上因数分解取得成功:', response.data)
      return response.data
    } else {
      // エラーが発生した場合の処理
      throw new Error(`売上因数分解取得処理エラー: ${response.statusText}`)
    }
  } catch (error) {
    // 例外が発生した場合のエラーハンドリング
    console.error('売上因数分解取得処理エラー:', error)
    throw new Error('売上因数分解取得処理に失敗しました。')
  }
}

export default fetchSalesFactorizationData
