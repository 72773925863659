import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'
import ComparisonText from '../../components/ComparisonText'
import { SalesFactorizationBoxProps } from '../../props/SalesFactorizationProps'
import { factorizationBoxHeight } from '../../utils/Utils'

const SalesFactorizationBox: React.FC<SalesFactorizationBoxProps> = ({
  title,
  dateType,
  data,
  unit,
  decimal,
  borderType,
}) => {
  const theme = useTheme()

  return (
    <Box
      // flex={1}
      sx={{
        padding: 2,
        // margin: 2,
        borderRadius: 1,
        height: factorizationBoxHeight,
        width: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        border: `1px ${borderType}`,
        borderColor: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
      }}
    >
      {/* タイトル */}
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{ color: theme.palette.text.primary }}
      >
        {title}
      </Typography>
      {/* 実績表示 */}
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          align="center"
          sx={{ color: theme.palette.text.primary }}
        >
          {/* {(67483967).toLocaleString()} */}
          {decimal
            ? Number(data.total.toFixed(1)).toLocaleString()
            : Math.round(data.total).toLocaleString()}
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          align="center"
          sx={{ marginTop: 'auto', color: theme.palette.text.primary }}
        >
          {unit}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          width={180}
          justifyContent="center"
          alignItems="center"
        >
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: 12, color: theme.palette.text.primary }}
            >
              {dateType === '0' ? '前週' : '前年'}
            </Typography>
          </Box>
          <Box
            flex={1.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ComparisonText value={data.ratio} unit="%" decimal={false} />
          </Box>
          <Box flex={1} />
        </Box>
        <Box width={6} /> {/* Spacer */}
        <ComparisonText value={data.diff} unit={unit} decimal={decimal} />
      </Box>
    </Box>
  )
}

export default SalesFactorizationBox
