import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Box, IconButton, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateView } from '@mui/x-date-pickers/models'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale/ja'
import React, { useState } from 'react'

interface CustomDatePickerProps {
  dateType: string
  selectedDate: string
  onDateChange: (date: string | null) => void
}

// 前月に変更する関数

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  dateType,
  selectedDate,
  onDateChange,
}) => {
  const theme = useTheme()

  const views: readonly DateView[] =
    dateType === '1' ? ['year', 'month'] : ['year', 'month', 'day']

  const [pickedDate, setPickedDate] = useState<string | null>(null)
  const handleDateChange = (date: Date | null) => {
    if (date) {
      // dateTypeに応じてフォーマットを変更
      const formattedDate =
        dateType === '1'
          ? format(date, 'yyyy-MM') // "YYYY-MM"形式
          : format(date, 'yyyy-MM-dd') // "YYYY-MM-DD"形式
      setPickedDate(formattedDate)
    } else {
      setPickedDate(null)
    }
  }

  const handlePreviousDate = () => {
    if (selectedDate) {
      const date = new Date(selectedDate)
      if (dateType === '0') {
        // 日次
        date.setDate(date.getDate() - 1)
      } else {
        // 月次
        date.setMonth(date.getMonth() - 1)
      }
      const newDate: string =
        dateType === '1'
          ? format(date, 'yyyy-MM') // "YYYY-MM"形式
          : format(date, 'yyyy-MM-dd') // "YYYY-MM-DD"形式
      onDateChange(newDate)
    }
  }

  // 翌月に変更する関数
  const handleNextDate = () => {
    if (selectedDate) {
      const date = new Date(selectedDate)
      if (dateType === '0') {
        // 日次
        date.setDate(date.getDate() + 1)
      } else {
        // 月次
        date.setMonth(date.getMonth() + 1)
      }
      const newDate: string =
        dateType === '1'
          ? format(date, 'yyyy-MM') // "YYYY-MM"形式
          : format(date, 'yyyy-MM-dd') // "YYYY-MM-DD"形式
      onDateChange(newDate)
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      // paddingY={2} // 必要に応じてパディングを調整
      // margin={0}   // 必要に応じてマージンを調整
      sx={{ backgroundColor: theme.palette.background.default }}
    >
      <IconButton onClick={handlePreviousDate}>
        <ArrowBackIosIcon sx={{ color: theme.palette.text.secondary }} />
      </IconButton>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}
      >
        {dateType === '1' ? '前月' : '前日'}
      </Typography>
      <Box mx={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <MobileDatePicker
            format={dateType === '1' ? 'yyyy/MM' : 'yyyy/MM/dd'} // フォーマットをdateTypeに応じて切り替え
            value={selectedDate ? new Date(selectedDate) : null} // 親コンポーネントから受け取った日付を使用
            onChange={handleDateChange}
            onClose={() => onDateChange(pickedDate)}
            minDate={new Date(2010, 0, 1)}
            views={views}
            slots={{
              toolbar: undefined, // ダイアログ上部のSelect date部分を非表示
            }}
            slotProps={{
              calendarHeader: { format: 'yyyy年MM月' },

              dialog: {
                sx: {
                  '& .MuiPickersDay-root': {
                    '&:hover': {
                      backgroundColor: '#e0e0e0', // ホバー時の背景色
                    },
                  },

                  '& .MuiPaper-root': {
                    backgroundColor: theme.palette.background.default, // カレンダー全体の背景色
                  },
                },
              },
              textField: {
                InputProps: {
                  sx: {
                    fontWeight: 'bold',
                    textAlign: 'center', // 全体の中央揃え
                    '& input': {
                      textAlign: 'center', // 入力部分の中央揃え
                    },
                    outline: '2px solid',
                    outlineColor: theme.palette.primary.main,
                  },
                  readOnly: true, // 入力を禁止
                },
              },
            }}
            sx={{
              backgroundColor: theme.palette.background.default,
              width: 120,
            }}
          />
        </LocalizationProvider>
      </Box>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        sx={{ color: theme.palette.text.secondary, fontSize: '14px' }}
      >
        {dateType === '1' ? '翌月' : '翌日'}
      </Typography>
      <IconButton onClick={handleNextDate}>
        <ArrowForwardIosIcon sx={{ color: theme.palette.text.secondary }} />
      </IconButton>
    </Box>
  )
}

export default CustomDatePicker
