import React from 'react'
import CommonContents from '../../components/CommonContents'
import { ProductSalesProps } from '../../props/ProductSalesProps'
import ProductSalesContent from './ProductSalesContent'

const ProductSales: React.FC<ProductSalesProps> = (props) => {
  const content: React.ReactNode = (() => {
    // if (loading) {
    //   return <div>Loading...</div> // ローディング表示
    // }

    // if (error) {
    //   return <div>{error}</div> // エラーメッセージ表示
    // }

    // if (!productSalesData) {
    //   return <div>No data available</div> // データがない場合の処理
    // }

    return (
      <ProductSalesContent
        dateType={props.dateType}
        categoryList={props.categoryList}
        productSalesList={props.data}
      />
    )
  })()

  return <CommonContents content={content} title="単品売上実績" />
}

export default ProductSales
