import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import AuthScreen from './screens/Auth/AuthScreen'
import HomeScreen from './screens/Home/HomeScreen'
import LoginScreen from './screens/Login/LoginScreen'
import TermsScreen from './screens/Terms/TermsScreen'

const App: React.FC = () => {
  console.log('app.tsx')
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AuthScreen />} />
        <Route path="/callback" element={<LoginScreen />} />
        <Route path="/home" element={<HomeScreen />} />
        <Route path="/terms" element={<TermsScreen />} />
      </Routes>
    </Router>
  )
}

export default App
