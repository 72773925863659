import axios from 'axios'

const dataLinkage = async (contractId: string) => {
  try {
    const isDebugMode = process.env.NODE_ENV === 'development' // 環境を切り替える条件

    const apikey = isDebugMode
      ? process.env.REACT_APP_API_KEY_DEBUG
      : process.env.REACT_APP_API_KEY

    const apiEndpoint = isDebugMode
      ? process.env.REACT_APP_API_GATEWAY_ENDPOINT_DEBUG
      : process.env.REACT_APP_API_GATEWAY_ENDPOINT

    const apiGatewayUrl = `${apiEndpoint}/data-linkage`

    // POSTリクエストを送信
    const response = await axios.post(
      apiGatewayUrl,
      { contractId: contractId },
      {
        headers: {
          'Content-Type': 'application/json', // JSONデータを送信するためのヘッダー
          'x-api-key': apikey, // APIキーを指定
        },
      }
    )

    if (response.status === 200) {
      // レスポンスが成功した場合の処理
      console.log('データ連携完了:', response.data)
      return response.data.message // useNavigateで画面遷移
    } else {
      // エラーが発生した場合の処理
      throw new Error(`データ連携処理エラー: ${response.statusText}`)
    }
  } catch (error) {
    // 例外が発生した場合のエラーハンドリング
    console.error('データ連携処理エラー:', error)
    throw new Error('データ連携処理に失敗しました。')
  }
}

export default dataLinkage
