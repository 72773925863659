import LogoutIcon from '@mui/icons-material/Logout'
import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import Logout from '../api/Logout'
import { redirectToSmaregiAuth } from '../api/SmaregiAuth'

const LogoutButton: React.FC = () => {
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const handleLogout = () => {
    // ログイン画面にリダイレクト
    // navigate('/')
    const logoutFromAPI = async () => {
      try {
        const result = await Logout()
        redirectToSmaregiAuth()
      } catch (error) {
        setError('エラーが発生しました')
      } finally {
        setLoading(false)
      }
    }
    logoutFromAPI()
  }

  return (
    <Tooltip title="ログアウト">
      <IconButton
        onClick={handleLogout}
        sx={{
          width: 60,
          height: 60,
          color: 'white', // アイコンの色
        }}
        aria-label="logout"
      >
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  )
}

export default LogoutButton
