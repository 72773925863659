import { ThreeDots } from 'react-loader-spinner'

import { Box, Toolbar } from '@mui/material'
import React from 'react'
import { drawerWidth } from '../utils/Utils'

const LoadingAnimation: React.FC = () => (
  <Box
    sx={{
      position: 'fixed', // ビューポートに固定
      top: '50%', // 上端から50%の位置
      left: `calc(50% + ${drawerWidth / 2}px)`, // 左端から50%の位置
      transform: 'translate(-50%, -50%)', // 完全に中央に調整
    }}
  >
    <Toolbar />
    <ThreeDots
      visible={true}
      height="80"
      width="80"
      color="#4fa94d"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </Box>
)

export default LoadingAnimation
