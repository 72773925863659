import { Box, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { format, subDays } from 'date-fns'
import React, { useState } from 'react'
import CustomDatePicker from '../../components/CustomDatePicker'
import StoreSelector from '../../components/StoreSelector'
import { useCategoryListContext } from '../../contexts/CategoryListContext'
import { useContractContext } from '../../contexts/ContractContext'
import { useStoreListContext } from '../../contexts/StoreListContext'
import { drawerWidth } from '../../utils/Utils'
import MonthlyScreenContent from './MonthlyScreenContent'

const MonthlyScreen: React.FC = () => {
  const theme = useTheme()

  const { contractId } = useContractContext()
  const { categoryList } = useCategoryListContext()
  const { storeList } = useStoreListContext()

  const dateType: string = '1'
  const today = new Date()
  const yesterday = subDays(today, 1)
  const defaultDate = format(yesterday, 'yyyy-MM')
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate)
  const [selectedStore, setSelectedStore] = useState<string[]>(
    storeList.map((e) => e.storeId)
  )

  const onDateChange = (date: string | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Toolbar />
      {/* ヘッダーセクション */}
      <Box
        position="fixed"
        top={0}
        left={drawerWidth}
        width={`calc(100vw - ${drawerWidth}px)`}
        sx={{
          backgroundColor: 'background',
          zIndex: (theme) => theme.zIndex.drawer,
          borderBottom: '1px solid ',
          borderColor: theme.palette.divider,
        }} // 上部固定
      >
        <Toolbar />
        {/* ヘッダーセクション */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          paddingY={2}
          sx={{ backgroundColor: theme.palette.background.default }}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            // justifyContent="center"
          >
            <StoreSelector
              storeList={storeList}
              onSelectionChange={(list) => {
                // リストが変わっていなければ更新しない
                if (selectedStore.length === list.length) {
                  const sortedPrelist = selectedStore.sort()
                  const sortedCurlist = list.sort()
                  const match = sortedCurlist.every(
                    (e, i) => e === sortedPrelist[i]
                  )
                  if (!match) {
                    setSelectedStore(list)
                  }
                } else {
                  setSelectedStore(list)
                }
              }}
            />
          </Box>
          <Box flex={1}>
            <CustomDatePicker
              dateType={dateType}
              selectedDate={selectedDate}
              onDateChange={onDateChange}
            />
          </Box>
          <Box flex={1} />
        </Box>
      </Box>
      <MonthlyScreenContent
        contractId={contractId}
        dtddate={selectedDate}
        dateType={dateType}
        storeList={selectedStore}
        categoryList={categoryList}
      />
    </Box>
  )
}

export default MonthlyScreen
