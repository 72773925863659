import React from 'react'
import CommonContents from '../../components/CommonContents'
import { SalesFactorizationContentProps } from '../../props/SalesFactorizationProps'
import SalesFactorizationContent from './SalesFactorizationContent'

const SalesFactorization: React.FC<SalesFactorizationContentProps> = (
  props
) => {
  const content: React.ReactNode = (() => {
    // if (loading) {
    //   return <LoadingAnimation /> // ローディング表示
    // }

    // if (error) {
    //   return <div>{error}</div> // エラーメッセージ表示
    // }

    // if (!salesFactorizationData) {
    //   return <div>No data available</div> // データがない場合の処理
    // }

    return (
      <SalesFactorizationContent dateType={props.dateType} data={props.data} />
    )
  })()

  return <CommonContents content={content} title="売上因数分解" />
}

export default SalesFactorization
