import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Chart } from 'react-chartjs-2'
import {
  SalesTransitionChartProps,
  SalesTransitionDataProps,
} from '../../props/SalesTransitionProps'
import { roundUpBar, roundUpLine } from '../../utils/Utils'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
)

const SalesTransitionChart: React.FC<SalesTransitionChartProps> = (props) => {
  const theme = useTheme()

  const previous: string = props.dateType === '0' ? '前週' : '前年'
  const preData: SalesTransitionDataProps[] = props.data.previous
  const curData: SalesTransitionDataProps[] = props.data.current

  const labels: string[] = []
  const preBarChartData: number[] = []
  const curBarChartData: number[] = []
  const preLineChartData: number[] = []
  const curLineChartData: number[] = []
  let preBarChartLabel: string = ''
  let curBarChartLabel: string = ''
  let preLineChartLabel: string = ''
  let curLineChartLabel: string = ''

  for (let i = 0; i < props.data.previous.length; i++) {
    labels.push(preData[i].date)
    if (props.chartType === '0') {
      preBarChartData.push(Number(preData[i].total.toFixed(0)))
      curBarChartData.push(Number(curData[i].total.toFixed(0)))
      preBarChartLabel = previous + '売上'
      curBarChartLabel = '売上'
      preLineChartLabel = ''
      curLineChartLabel = ''
    } else if (props.chartType === '1') {
      preBarChartData.push(Number(preData[i].transactionUnitPrice.toFixed(0)))
      curBarChartData.push(Number(curData[i].transactionUnitPrice.toFixed(0)))
      preLineChartData.push(Number(preData[i].transactionCount.toFixed(0)))
      curLineChartData.push(Number(curData[i].transactionCount.toFixed(0)))
      preBarChartLabel = previous + '取引単価'
      curBarChartLabel = '取引単価'
      preLineChartLabel = previous + '取引数'
      curLineChartLabel = '取引数'
    } else {
      preBarChartData.push(Number(preData[i].itemUnitPrice.toFixed(0)))
      curBarChartData.push(Number(curData[i].itemUnitPrice.toFixed(0)))
      preLineChartData.push(Number(preData[i].amountPerGuest.toFixed(1)))
      curLineChartData.push(Number(curData[i].amountPerGuest.toFixed(1)))
      preBarChartLabel = previous + '単価'
      curBarChartLabel = '商品平均単価'
      preLineChartLabel = previous + '点数'
      curLineChartLabel = '買上点数 / 人'
    }
  }

  const barMaxValue = Math.max(...preBarChartData, ...curBarChartData)
  const barChartMax = roundUpBar(barMaxValue)
  const lineMaxValue = Math.max(...preLineChartData, ...curLineChartData)
  const lineChartMax = roundUpLine(lineMaxValue)

  const data = {
    labels,
    datasets: [
      ...(props.chartType !== '0'
        ? [
            {
              type: 'line' as const,
              label: preLineChartLabel,
              borderColor: theme.palette.lineChart.light,
              borderWidth: 2,
              fill: false,
              data: preLineChartData,
              yAxisID: 'y1',
            },
            {
              type: 'line' as const,
              label: curLineChartLabel,
              borderColor: theme.palette.lineChart.main,
              borderWidth: 2,
              fill: false,
              data: curLineChartData,
              yAxisID: 'y1',
            },
          ]
        : []),
      {
        type: 'bar' as const,
        label: preBarChartLabel,
        backgroundColor: theme.palette.barChart.light,
        data: preBarChartData,
        borderColor: 'white',
        borderWidth: 2,
        yAxisID: 'y',
      },
      {
        type: 'bar' as const,
        label: curBarChartLabel,
        backgroundColor: theme.palette.barChart.main,
        data: curBarChartData,
        yAxisID: 'y',
      },
    ],
  }

  const options: ChartOptions = {
    maintainAspectRatio: false, // アスペクト比を無視
    scales: {
      y: {
        beginAtZero: true,
        max: barChartMax,
        ticks: {
          // stepSize: 7,
        },
        // ticks: {
        //   callback: function (value: number) {
        //     return value.toLocaleString()
        //   },
        // },
      },

      y1: {
        position: 'right',
        max: lineChartMax,
        display: props.chartType !== '0',
        beginAtZero: true,
        grid: {
          drawOnChartArea: false, // 左のグリッドと区別する場合にオフにする
        },
        // ticks: {
        //   callback: function (value: number) {
        //     return value + '%'
        //   },
        // },
      },
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  }

  return (
    <Box height={300}>
      <Chart type="bar" data={data} options={options} />
    </Box>
  )
}

export default SalesTransitionChart
