import React, { createContext, ReactNode, useContext, useState } from 'react'
import { CategoryProps } from '../props/CategoryProps'

// Contextの型
interface CategoryListContextType {
  categoryList: CategoryProps[]
  setCategoryList: (categoryList: CategoryProps[]) => void
}

// デフォルト値の設定
const CategoryListContext = createContext<CategoryListContextType>({
  categoryList: [],
  setCategoryList: () => {},
})

// ContractProviderを作成
export const CategoryListProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [categoryList, setCategoryList] = useState<
    CategoryProps[] | CategoryProps[]
  >([])

  return (
    <CategoryListContext.Provider value={{ categoryList, setCategoryList }}>
      {children}
    </CategoryListContext.Provider>
  )
}

// useContractContextフックを作成して簡単にContextにアクセスできるようにする
export const useCategoryListContext = () => useContext(CategoryListContext)
