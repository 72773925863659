import { CategoryProps } from '../props/CategoryProps'

export const drawerWidth = 200
export const factorizationBoxHeight = 120

export const noSelectCategory: CategoryProps = {
  categoryId: 'noSelect',
  categoryId1: '',
  categoryId2: '',
  categoryId3: '',
  categoryName: '全部門',
  categoryAbbr: '全部門',
  level: 0,
}

export function roundUpBar(value: number): number {
  const digits = Math.pow(10, Math.floor(Math.log10(value))) // 桁数の基準を計算
  const rounded = Math.ceil(value / digits) * digits // 丸めて次の大きな値に
  return rounded
}

export function roundUpLine(value: number): number {
  let step: number

  if (value <= 10) {
    step = 10 // 10単位で丸める
  } else if (value <= 50) {
    step = 50 // 50単位で丸める
  } else if (value <= 100) {
    step = 100 // 100単位で丸める
  } else if (value <= 500) {
    step = 500 // 500単位で丸める
  } else {
    step = 1000 // 1000単位で丸める
  }
  return Math.ceil(value / step) * step
}
