import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { CategoryListProvider } from './contexts/CategoryListContext'
import { ContractProvider } from './contexts/ContractContext'
import { CookieExistsProvider } from './contexts/CookieExistsContext'
import { MenuToggleProvider } from './contexts/MenuToggleContext'
import { StoreListProvider } from './contexts/StoreListContext'
import { UserProvider } from './contexts/UserContext'
import themes from './theme/themes'
// import './style/style.css'

// 開発環境以外で console.log() を抑止
// import.meta.env.MODE !== "dev" && (
//   console.log = () => {},
//   console.debug = () => {},
//   console.table = () => {}
// );

ReactDOM.render(
  <ThemeProvider theme={themes.themeColor}>
    <ContractProvider>
      <UserProvider>
        <CategoryListProvider>
          <StoreListProvider>
            <CookieExistsProvider>
              <MenuToggleProvider>
                <React.StrictMode>
                  <CssBaseline />
                  <App />
                </React.StrictMode>
              </MenuToggleProvider>
            </CookieExistsProvider>
          </StoreListProvider>
        </CategoryListProvider>
      </UserProvider>
    </ContractProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
